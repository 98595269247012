import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Collapsible from 'react-collapsible';
import PageTitle from "../Components/PageTitle";

export default function FAQ() {

  const FAQS = [
    {
      title: "What is ear irrigation?",
      content: `Ear irrigation is a warm water wash of the ear canal. A machine is used to gently spray water into the ear pushing the wax out of the ear and washing it away.
      You may have heard of or experienced ear syringing in the past but this is an outdated term, though the principle is the same, ear syringing is no longer considered safe practice.`
    },
    {
      title: "What is microsuction?",
      content: `Microsuction is a technique where a machine is used to gently suck the wax from your ear. The method is quite noisy so there are restrictions on how long I can spend attempting to remove the blockage.`
    },
    {
      title: "Which one is best for me?",
      content: `For successful irrigation the wax should be soft and sticky to allow the warm water to wash it away. If the wax is hard and dry microsuction will be more appropriate.
      If you have any history of ear problems such as recurrent infections, perforated (burst) ear drums, surgery or investigation by ENT it may be that one method is more appropriate than the other so please let me know. This will form part of my assessment of your ears and ear history.`
    },
    {
      title: "How long does it take to remove my wax?",
      content: `Irrigation can take anywhere from 20 seconds to 20 minutes per ear. I will also spend around 10-20 minutes assessing your ears and ensuring the safety of the procedure for your particular case.
      Microsuction can take a little longer but will not take any more than 10 minutes per ear as this exceeds the recommendation for exposure to loud noise.`
    },
    {
      title: "What if you visit but there is no wax or blockage in my ear?",
      content: `If there is no wax but you’ve been experiencing hearing loss my recommendation would be to book a hearing test.
      A £20 consultation fee is still payable.`
    },
    {
      title: "Do I need to use olive oil ear drops?",
      content: `It’s recommended to administer olive oil drops 2-3 times daily to both ears for at least 5 days prior to your appointment. This is to ensure the wax is soft enough to be removed with the warm water irrigator. If the wax is not soft, irrigation is unlikely to be successful however microsuction may still be appropriate.`
    },
    {
      title: "Can I use another wax softener?",
      content: `You don’t have to use olive oil, other options are available from pharmacies such as Sodium Bicarbonate, however, I recommend olive oil as it is best practice, natural and inexpensive. If you choose to use sodium bicarbonate be aware that you should not use it for longer than 2 weeks. Olive oil is perfectly safe for long term use.
      Another option you may find at the pharmacy is Urea Hydrogen Peroxide, I don’t recommend this as find it very harsh and causes the wax to change consistency making it difficult to remove. If you wish to use this please be sure to read the information leaflet in the box as it should not be used for more than 3-4 days.`
    },
    {
      title: "Does it hurt?",
      content: `Ear irrigation should not hurt, some people find it a little uncomfortable but more often than not I find that people described it as being quite pleasant. 
      Microsuction may be uncomfortable as dry wax can adhere to the skin inside the ear canal, when the wax is pulled away it can pull the top layer of skin away with it.`
    },
    {
      title: "What if you can’t clear the blockage?",
      content: `If the blockage can’t be cleared I will recommend you use olive oil drops 2-3 times a day for 2 weeks, I can then return to try to clear the rest of the blockage. In this case the consultation fee will not be payable for the second appointment but the irrigation fee of £20 per ear will still be charged.`
    },
    {
      title: "How can I pay?",
      content: `I can accept cash or card at the time of the appointment.`
    },
    {
      title: "What if I need to cancel my appointment?",
      content: `Please give me at least 24hours notice if you need to cancel or change your appointment so that the slot can be offered to someone else. If you do not cancel within 24 hours the consultation fee of £20 will still be charged.`
    },
    {
      title: "How do I prepare for my appointment?",
      content: `If you have a preference for irrigation please ensure you have used a wax softener for at least 5 days prior to your appointment. I will need access to a plug socket for the procedure and you may like a comfy chair to sit on.`
    },
    {
      title: "What qualifications does The Ear Nurse have?",
      content: `I have a 1st class honours degree in Adult Nursing from Plymouth University as well as over 7 years of experience as a district nurse in Plymouth, providing ear irrigation services. I have also independently completed Ear Care training provided by Livewell Southwest and microsuction training with The Hearing Lab.`
    },
    {
      title: "Is The Ear Nurse regulated?",
      content: `Yes. I am regulated by The Nursing and Midwifery Council (NMC) and a member of the Royal College of Nursing (RCN). I also have indemnity insurance and public liability insurance. `
    },
    {
      title: "Why should I pay for ear irrigation when I’ve had it free in the past?",
      content: `See the article linked below:

      https://www.bbc.co.uk/news/uk-politics-54296737`
    },
  ]

  return (
    <div>
      <PageTitle title={"FAQ"} />

      <div className="lg:w-2/3 mx-auto">

        {FAQS.map((faq, index) => {
          return (
            <div className="">
              <Collapsible trigger={faq.title}
                className="font-bold text-xl font-roboto "
                openedClassName="whitespace-pre-line font-bold text-xl font-roboto"
                triggerClassName="p-4 block hover:bg-pink-200"
                triggerOpenedClassName="p-4 block hover:bg-pink-200"
                contentOuterClassName="text-lg font-medium border-b border-black"
                contentInnerClassName="p-4"
              >
                <p dangerouslySetInnerHTML={{__html: faq.content}}></p>
              </Collapsible>
            </div>
          )
        })}
      </div>
    </div>
  )
}