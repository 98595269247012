export default function PageTitle({ title }) {
  return (
    <>
      <div className="text-en-blue border-b-2 mb-6 px-6">
        <h1 className="font-bold text-3xl py-2 mb-0">{title}</h1>
        <div className="bg-en-blue rounded-lg w-20 h-1"></div>
      </div>
    </>
  );
}
