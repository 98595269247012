import { InlineWidget } from "react-calendly";
import PageTitle from "../Components/PageTitle";
import '../App.css';

export default function BookingPage() {

  return (
    <div>
      <PageTitle title={"Booking Page"} />

      <div className="mb-10">
        <InlineWidget
          className="overflow-hidden"
          url="https://calendly.com/theearnurseplymouth"
          styles={{ height: "660px" }}
        />

        {/* <InlineWidget
          className="overflow-hidden"
          url="https://calendly.com/theearnurseplymouth/bookclinic"
          styles={{ height: "660px" }}
        /> */}
      </div>

    </div>
  )
}