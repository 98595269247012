import PageTitle from "../Components/PageTitle";
import { CheckBadgeIcon, EyeIcon, GlobeEuropeAfricaIcon, HomeIcon, RocketLaunchIcon, UserCircleIcon } from "@heroicons/react/24/outline";

import Lauren from "../Assets/Lauren.jpg"

export default function Home() {

  return (
    <div>
      <PageTitle title={"Home"} />

      <div className="mt-5 mb-16 lg:mb-20 px-10 lg:px-[15vw]">
        <h1 className="text-3xl mb-2 font-bold text-en-blue">About the company</h1>
        <div className="flex flex-col lg:flex-row">
          <div className="flex justify-center">
            <img src={Lauren} className="min-w-[15rem] max-h-[20rem] lg:max-w-[20rem] lg:max-h-[22rem] p-2 lg:mr-10 object-contain" />
          </div>
          <div>
            <p className="text-lg">Hi I’m Lauren, a registered nurse and a Plymothian.
              I studied adult nursing at the University of Plymouth, graduating with a 1st class honours degree in 2015. Since then I have worked in the community as a district nurse and a practice nurse, providing ear care, including irrigation, to people with a variety of backgrounds. </p>
              <br />
            <p className="text-lg">Over the course of my career I have already seen big changes in NHS and GP service provision and identified a need for additional services which are now deemed unnecessary by the local clinical commissioning group (CCG). In areas still providing ear care in particular, wait times can be so long people are spending months debilitated by a loss of their vital sense of hearing. I knew I could help these people and with the support of my young family I began practicing as The Ear Nurse in 2022.</p>
            <br />
            <p className="text-lg">Although perhaps not the most glamorous job, ear irrigation has long been one of my favourite clinical skills as it is incredibly satisfying. Being witness to the moment when someone, who has been unable to hear for some time, suddenly regains the ability to hear, is incredible and, at times, even emotional.</p>
            <br />
            <p className="text-lg">I genuinely love this job, making a true and often immediate difference to someone’s life for the better is more than I could have imagined when I first started my nurse training. It may be cliché but becoming The Ear Nurse is quite honestly a dream come true for me!</p>
          </div>
        </div>

      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-7 px-10 lg:px-[2vw] lg:gap-6 mb-20" >

        <div className="pt-5 flex flex-col items-center text-center">
          <CheckBadgeIcon className="w-20 h-20 mb-5 text-pink-600" />
          <h1 className="text-2xl font-bold text-en-blue">Qualified & Experienced</h1>
          <p className="pt-2 text-lg">Lauren has over 7 years of experience as a district nurse in Plymouth, providing ear car and wax removal services. You can be sure you're in safe and experienced hands.</p>
        </div>

        <div className="pt-5 flex flex-col items-center text-center">
          <UserCircleIcon className="w-20 h-20 mb-5 text-pink-600" />
          <h1 className="text-2xl font-bold text-en-blue">Professional & Friendly</h1>
          <p className="pt-2 text-lg">A thorough consultation is carried out at each visit meaning care is tailored to each individual's needs. Help and advice is always available.</p>
        </div>

        <div className="pt-5 flex flex-col items-center text-center">
          <GlobeEuropeAfricaIcon className="w-20 h-20 mb-5 text-pink-600" />
          <h1 className="text-2xl font-bold text-en-blue">Home Visits</h1>
          <p className="pt-2 text-lg">We offer you professional consultations and ear wax removal procedures from the comfort of your own home. </p>
        </div>

      </div>

    </div>
  )
}