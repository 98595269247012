import Facebook from '../Assets/facebook.png';
import Instagram from '../Assets/instagram.png';
import Telephone from '../Assets/telephone.png';
import Email from '../Assets/email.png';

export default function Socials() {

  return (
    <div className="flex justify-end gap-4 lg:gap-5 -mb-2 pt-3 px-4">
      <a href='https://www.facebook.com/profile.php?id=100085459600083' target="_blank"> <img src={Facebook} className="h-8 w-8" /> </a>
      <a href='https://www.instagram.com/the.ear.nurse/?fbclid=IwAR2wJMDVusxfTEk_yeWR6Lv3qY6b2q_fSWlu955ooPtg522511-5WXsGzn8' target="_blank"> <img src={Instagram} className="h-8 w-8" /> </a>
      <a href='tel:07378813644'> <img src={Telephone} className="h-8 w-8" /> </a>
      <a href='mailto:lauren@theearnurseplymouth.co.uk'> <img src={Email} className="h-8 w-8" /> </a>
    </div>
  )
}