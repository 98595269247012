import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../Helpers/Classnames";
import MobileNavigation from "./MobileNavigation";
import Socials from "./Socials";



export default function Navigation() {
  const [open, setOpen] = useState(false);

  const navigation = [
    {
      name: "Home",
      href: "/",
      end: true,
    },
    {
      name: "Services",
      href: "/services"
    },
    {
      name: "Reviews",
      href: "/reviews"
    },
    {
      name: "FAQs",
      href: "/faqs"
    },
    {
      name: "Contact Us",
      href: "/contact-us"
    }
  ]

  return (
    <nav className="my-auto p-5 bg-en-blue">
      {/* Desktop nav */}
      <div className="flex">
        <div className="hidden flex lg:flex">
          {navigation.map((item) => {
            return (
              <NavLink
                key={item.name}
                to={item.href}
                end={item.end ? item.end : false}
              >
                {({ isActive }) => (
                  <span
                    className={classNames(
                      isActive
                        ? "text-en-pink"
                        : "text-white hover:bg-gray-50 hover:text-gray-900",
                      "flex my-auto mx-1 px-3 py-1.5 text-lg font-small rounded-md"
                    )}
                  >
                    {item.name}
                  </span>
                )}
              </NavLink>
            );
          })}
        </div>

        <NavLink key={"Book Now"} to={"/booking"} end={false}>
          <span
            className="text-white bg-pink-400 flex whitespace-nowrap mx-2 px-4 py-1.5 text-xl font-medium rounded-md hover:bg-pink-500 hover:text-gray-900">
            {"Book Now"}
          </span>
        </NavLink>

        {/* Mobile navigation */}
        <button className="lg:hidden ml-5 my-auto" onClick={() => setOpen(!open)} aria-controls="mobile-menu" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          {!open ? (
            <Bars3Icon className="h-10 w-10 text-white" aria-hidden="true" />
          ) : (
            <XMarkIcon className="h-10 w-10 text-white" aria-hidden="true" />
          )}
        </button>
      </div>

      <div>
        <Socials />
      </div>

      <MobileNavigation navigation={navigation} open={open} setOpen={setOpen} />

    </nav>
  )
}