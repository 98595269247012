import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";

import classNames from "../Helpers/Classnames";

export default function MobileNavigation({navigation, open, setOpen}) {
  const cancelButtonRef = useRef(null);
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >

        <div className="fixed z-10 inset-0 overflow-y-hidden lg:hidden w-full">
          <div className="flex mt-[14.5vh] items-start justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative bg-en-blue p-3 pb-6 text-left overflow-hidden transform transition-all w-full">

                {navigation.map((item) => {
                  return (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      end={item.end ? item.end : false}
                      onClick={() => setOpen(false)}
                    >
                      {({ isActive }) => (
                        <span
                          className={classNames(
                            isActive
                              ? "text-en-pink"
                              : "text-white hover:bg-gray-50 hover:text-gray-900",
                            "flex my-auto mx-1 px-3 py-1.5 text-lg font-small rounded-md"
                          )}
                        >
                          {item.name}
                        </span>
                      )}
                    </NavLink>
                  );
                })}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
