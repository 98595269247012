import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import MainLayout from "./Layouts/MainLayout";
import BookingPage from "./Pages/BookingPage";
import ContactUs from "./Pages/ContactUs";
import FAQ from "./Pages/FAQ";
import Home from "./Pages/Home";
import Reviews from "./Pages/Reviews";
import Services from "./Pages/Services";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const router = createBrowserRouter(
  [
    {
      path: "",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Home />
        },
        {
          path: "booking",
          element: <BookingPage />
        },
        {
          path: "services",
          element: <Services />
        },
        {
          path: "contact-us",
          element: <ContactUs />
        },
        {
          path: "reviews",
          element: <Reviews />
        },
        {
          path: "faqs",
          element: <FAQ />
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />
        },
      ],
    },
    //   Redirect to home?
    { path: "*", element: <div>404 Route</div> },
  ]
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
