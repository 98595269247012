import PageTitle from "../Components/PageTitle";

export default function Reviews() {

  const facebookReviews = [
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmandy.martin.355138%2Fposts%2Fpfbid0BGuCcE3namjfAgvF9hgZeRGwM6VGL8wtgnWQYQnDLqwgbFyGpCNkixVZAEevFZm9l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fphoebe.martin.5811%2Fposts%2Fpfbid02LtJpyRKKYokgPctKthTiSy1DbuVsowaPgsJxycjttATS11zYgVoesxUKGGrUsAP5l&show_text=true",
  ];

  //TODO: Add reviews from google
  //TODO: Fix facebook reviews height
  return (
    <div>
      <PageTitle title={"Reviews"} />
      <div className="px-2 items-center flex">
        <h1 className="text-2xl font-bold">Check and Leave Reviews here:</h1>
        <a className="pl-4 text-xl underline text-blue-500" target="_blank" href="https://www.facebook.com/profile.php?id=100085459600083&sk=reviews">The Ear Nurse Facebook page</a>
      </div>

      <div className="px-2 mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {facebookReviews.map((review) => {
          return (
            <iframe className="w-full h-[250px] md:h-[300px] lg:h-[250px] overflow-hidden" title={review} src={review}  scrolling="yes" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          )
        })}
      </div>
    </div>
  )
}