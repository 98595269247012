import PageTitle from "../Components/PageTitle";

export default function Home() {
//TODO: This
  return (
    <div>
      <PageTitle title={"Privacy Policy"} />
    
      <div>
      Website Privacy notice 
  
  
  This company is The Ear Nurse.  The registered address is 21 Southernway, Plymouth, Devon, England, PL9 8TB. In this privacy notice we will refer to ourselves as ‘we’, ‘us’ or ‘our’. 
    
  You can get hold of us in any of the following ways: 
    
  (a) by phoning us on 07378813644; or 
  (b) by e-mailing us at lauren@theearnurseplymouth.co.uk 
    
  We take the privacy, including the security, of personal information we hold about you seriously. This privacy notice is designed to inform you about how we collect personal information about you and how we use that personal information.  You should read this privacy notice carefully so that you know and can understand why and how we use the personal information we collect and hold about you. 
    
  We do not have a Data Protection Officer, but if you have any questions about this privacy notice or issues arising from it then you should contact Lauren Lodge, who is responsible for matters relating to data protection at our organisation, including any matters in this privacy notice.  You can contact them using the details set out above. 
    
  We may issue you with other privacy notices from time to time, including when we collect personal information from you.  This privacy notice is intended to supplement these and does not override them. 
    
  We may update this privacy notice from time to time. This version was last updated on 25/11/2022. 
    
  2. Key Definitions 
  The key terms that we use throughout this privacy notice are defined below, for ease: 
  Data Controller: under UK data protection law, this is the organisation or person responsible for deciding how personal information is collected and stored and how it is used.   
  Data Processor: a Data Controller may appoint another organisation or person to carry out certain tasks in relation to the personal information on behalf of, and on the written instructions of, the Data Controller. (This might be the hosting of a site containing personal data, for example, or providing an email marketing service that facilitates mass distribution of marketing material to a Data Controller’s customer-base.) 
  Personal Information: in this privacy notice we refer to your personal data as ‘personal information’. ‘Personal information’ means any information from which a living individual can be identified.  It does not apply to information which has been anonymised. 
  Special Information – certain very sensitive personal information requires extra protection under data protection law.  Sensitive data includes information relating to health, racial and ethnic origin, political opinions, religious and similar beliefs, trade union membership, sex life and sexual orientation and also includes genetic information and biometric information.   
  3. Details of personal information which we collect and hold about you 
  3.1 Set out below are the general categories [and details of retention periods in relation to those categories (see section 8 below for more details about retention)] and in each case the types of personal information which we collect, use and hold about you: 
  General Category	Types of Personal Data in that category	Retention Periods
  Identity Information	This is information relating to your identity such as your name (including any previous names and any titles which you use), gender, marital status and date of birth	8 years (in line with NHS England Records Management Code of Practice 2021)
  Contact Information	This is information relating to your contact details such as e-mail address, addresses, telephone numbers	8 years (in line with NHS England Records Management Code of Practice 2021)
      
      
  Transaction Information	This is information relating to transactions between us such as details of the goods, services and/or digital content provided to you and any returns details.	8 years (in line with NHS England Records Management Code of Practice 2021)
  Survey Information	This is information which we have collected from you or which you have provided to us in respect of surveys and feedback.	8 years (in line with NHS England Records Management Code of Practice 2021)
      
      
  Records of treatment provided	This relates to thorough documentation of the consultation assessment and outcome required in line with the Royal College of Nursing Standards for Nurses and Code of Practice.	8 years (in line with NHS England Records Management Code of Practice 2021)
  
  3.2 The types of personal data we collect about you may differ from person to person, depending on who you are and the relationship between us. 
    
  4. Details of special information which we collect and hold about you 
  4.1 We do not collect or hold any special information about you. 
  4.2 We do not collect information from you relating to criminal convictions or offences. 
  5. Details of how and why we use personal information 
  5.1 We are only able to use your personal information for certain legal reasons set out in data protection law.  There are legal reasons under data protection law other than those listed below, but in most cases, we will use your personal information for the following legal reasons: 
  (a) Contract Reason: this is in order to perform our obligations to you under a contract we have entered into with you; 
  (b) Legitimate Interests Reason: this is where the use of your personal information is necessary for our legitimate interests, so long as that legitimate interest does not override your fundamental rights, freedoms or interests. 
  (c) Legal Obligation Reason: this is where we have to use your personal information in order to perform a legal obligation by which we are bound; and 
  (d) Consent Reason: this is where you have given us your consent to use your personal information for a specific reason or specific reasons. 
  5.2 So that we are able to provide you with ear care services, we will need your personal information.  If you do not provide us with the required personal information, we may be prevented from completing assessments resulting in being unable to safely assess and irrigate your ears. 
  5.4 It is important that you keep your personal information up to date.  If any of your personal information changes, please contact us as soon as possible to let us know.  If you do not do this then we may be prevented from contacting you again for follow-up appointments. 
  5.5 Where we rely on consent for a specific purpose as the legal reason for processing your personal information, you have the right under data protection law to withdraw your consent at any time.  If you do wish to withdraw your consent, please contact us using the details set out at the beginning of this notice.  If we receive a request from you withdrawing your consent to a specific purpose, we will stop processing your personal information for that purpose, unless we have another legal reason for processing your personal information, in which case, we will confirm that reason to you.   
  5.6 We have explained below the different purposes for which we use your personal information and, in each case, the legal reason(s) allowing us to use your personal information.  Please also note the following: 
  (a) if we use the Legitimate Interests Reason as the legal reason for which we can use your personal information, we have also explained what that legitimate interest is; and 
  (b) for some of the purposes we may have listed more than one legal reason on which we can use your personal information, because the legal reason may be different in different circumstances.  If you need confirmation of the specific legal reason that we are relying on to use your personal data for that purpose, please contact us using the contact details set out at the start of this privacy notice. 
  Purpose	Legal Reason(s) for using the personal information
  To register you as a client	In order to contact you to discuss personal healthcare history and ensure the appropriateness of a consultation.
  For record keeping	To comply with professional obligation to record the details of healthcare provision and intervention.
  In the interest of safety and public protection which override the need for confidentiality	To protect the personal safety of clients and the public
  To improve the service, and/or digital content which we supply	In order to improve the services, and/or digital content for future customers and to grow our business
  To recommend and send communications to you about future services, promotions and/or digital content that you may be interested in.	in order to grow our business
  To ensure the smooth running and correct operation of our website	To ensure our website runs correctly
  
    
  5.7 Sometimes we may anonymise personal information so that you can no longer be identified from it and use this for our own purposes. In addition, sometimes we may use some of your personal information together with other people’s personal information to give us statistical information for our own purposes.  Because this is grouped together with other personal information and you are not identifiable from that combined data we are able to use this. 
  5.8 Under data protection laws we can only use your personal information for the purposes we have told you about, unless we consider that the new purpose is compatible with the purpose(s) which we told you about.   If we want to use your personal information for a different purpose which we do not think is compatible with the purpose(s) which we told you about then we will contact you to explain this and what legal reason is in place to allow us to do this. 
  6. Details of how we collect personal information and special information 
  6.1 We usually collect your name, address, date of birth, phone number, email address, ear health history directly from you when you complete our pre-assessment questionnaire, contact us by e-mail, telephone, in writing or otherwise. 
  7. Details about who personal Information may be shared with 
  7.1 We may need to share your personal information with other organisations or people.  These organisations include: 
  (a) The police, if it is felt that the immediate safety of an individual is deemed to be at risk. 
  (b) Social services, if the safety of an individual is deemed to be at risk and/or it is felt that there is evidence of abuse. 
  7.2 Depending on the circumstances, the organisations or people who we share your personal information with will be professional bodies and services who are also bound under confidentiality agreements. 
  7.3 We do not sell or trade any of the personal information which you have provided to us. 
    
  8. Details about transfers to countries outside of the EEA 
  8.1 We do not transfer your personal information outside of the EEA. 
  9. Details about how long we will hold your personal information 
  9.1 We will only hold your personal data for as long as is necessary.  How long is necessary will depend upon the purposes for which we collected the personal information (see section 4 above) and whether we are under any legal obligation to keep the personal information (such as in relation to accounting or auditing records or for tax reasons).  We may also need to keep personal information in case of any legal claims. 
  9.2 We have set out above the details of our retention periods for different types of data. You can find them in in section 2 [and also in section 3]. 
    
  10. YOUR RIGHTS UNDER DATA PROTECTION LAW 
  11.1 Under data protection laws you have certain rights in relation to your personal information, as follows: 
  (a) Right to request access: (this is often called ‘subject access’).  This is the right to obtain from us a copy of the personal information which we hold about you. We must also provide you with certain other information in response to these requests to help you understand how your personal information is being used. 
  (b) Right to correction: this is the right to request that any incorrect personal data is corrected and that any incomplete personal data is completed. 
  (c) Right to erasure: (this is often called the “right to be forgotten”). This right only applies in certain circumstances.  Where it does apply, you have the right to request us to erase all of your personal information. 
  (d) Right to restrict processing: this right only applies in certain circumstances.  Where it does apply, you have the right to request us to restrict the processing of your personal information. 
  (e) Right to data portability: this right allows you to request us to transfer your personal information to someone else. 
  (f) Right to object: you have the right to object to us processing your personal information for direct marketing purposes.  You also have the right to object to us processing personal information where our legal reason for doing so is the Legitimate Interests Reason (see section 4 above) and there is something about your particular situation which means that you want to object to us processing your personal information. In certain circumstances you have the right to object to processing where such processing consists of profiling (including profiling for direct marketing). 
  11.2 In addition to the rights set out in section 10.1, where we rely on consent as the legal reason for using your personal information, you have the right to withdraw your consent.  Further details about this are set out in section 4.5. 
    
  11.3 If you want to exercise any of the above rights in relation to your personal information, please contact us using the details set out at the beginning of this notice.  If you do make a request then please note: 
  (a) we may need certain information from you so that we can verify your identity; 
  (b) we do not charge a fee for exercising your rights unless your request is unfounded or excessive; and 
  (c) if your request is unfounded or excessive then we may refuse to deal with your request. 
    
  12. Marketing 
  12.1 We do not pass your personal information on to any third parties for marketing purposes. 
  13. Complaints 
  If you are unhappy about the way that we have handled or used your personal information, you have the right to complain to the UK supervisory authority for data protection, which is the Information Commissioner’s Office (ICO).  Please do contact us in the first instance if you wish to raise any queries or make a complaint in respect of our handling or use of your personal information, so that we have the opportunity to discuss this with you and to take steps to resolve the position.  You can contact us using the details set out at the beginning of this privacy notice. 
  14. Third Party Websites 
  Our website may contain links to third party websites.  If you click and follow those links then these will take you to the third party website.  Those third party websites may collect personal information from you and you will need to check their privacy notices to understand how your personal information is collected and used by them. 
    
  General Privacy Notice[126153] 
  
  
      </div>
    </div>
  )
}