import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import LoadingWheel from "../Components/LoadingWheel";
import PageTitle from "../Components/PageTitle";

export default function ContactUs() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [completed, setCompleted] = useState(false);

  const SERVICE_ID = "service_hnbvn2h";
  const TEMPLATE_ID = "template_rj69afj";
  const PUBLIC_KEY = "2GnatCZtoBmM1fV7f";

  const handleSubmit = (e) => {
    e.preventDefault();

    setSending(true);
    setError("");

    if (name === "" || email === "" || message === "") {
      setError("Please fill out all fields!");
      setSending(false);
      return;
    }

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        setSending(false);
        setCompleted(true);
      }, (error) => {
        console.log(error.text);
        setSending(false);
        setError("Error sending message");
      });
  }


  return (
    <div>
      <PageTitle title={"Contact Us"} />
      <div className="px-2 mb-4">
        <h1 className="text-xl">Call me on <a href="tel:07378813644" className="underline">07378 813644</a> or fill out the form below:</h1>
      </div>

      <div className="px-2">
        {!completed ? (
          <form ref={form} onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name" type="text" name="clientName" placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email" type="email" placeholder="Email" name="clientEmail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message" type="text" placeholder="Please enter your message" rows={8} name="message"
                onChange={(e) => setMessage(e.target.value)} />
            </div>

            {sending ? (
              <div className="my-6 mr-2">
                <LoadingWheel width="w-8" height="h-8" />
              </div>
            ) : (
              <div className="flex items-center">
                <button className="bg-en-blue hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                  Send
                </button>
                <p className="text-red-500 ml-5 text-sm italic">{error}</p>
              </div>
            )}

          </form>
        ) : (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <p className="text-center text-2xl font-bold">Thank you for your message!</p>
            <p className="text-center text-lg">I will get back to you as soon as possible.</p>
          </div>
        )
        }
      </div >
    </div >
  )
}