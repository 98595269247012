import { NavLink } from "react-router-dom";
import PageTitle from "../Components/PageTitle";

export default function Services() {

  const services = [
    {
      name: "Microsuction",
      description: "Wax is removed from the ear canal using a tiny suction device.",
      price: ""
    },
    {
      name: "Irrigation",
      description: "Warm water cleanse of the ear canal.",
      price: ""
    },
    {
      name: "Consultation",
      description: "A full pre-procedure assessment including visual examination of both ears. All bookings will begin with a consultation.",
      price: "£25"
    },
    {
      name: "Wax Removal From One Ear",
      // description: "Water cleanse and wax removal of one ear.",
      price: "£45"
    },
    {
      name: "Wax Removal From Both Ears",
      // description: "Water cleanse and wax removal of both ears.",
      price: "£65"
    },
    {
      name: "Same Household Discount",
      description: "Consultation and wax removal from both ears for two people.",
      price: "£100"
    },
  ];

  const areas = [
    "Plymouth", "Ivybridge", "Modbury", "South Brent", "Yealmpton", "Yelverton",
  ];

  const workingHours = [
    // {
    //   days: "Monday",
    //   time: "1000 - 1600"
    // },
    // {
    //   days: "Tuesday",
    //   time: "1730 - 1930"
    // },
    {
      days: "Wednesday",
      // time: "0900 - 1700"
    },
    {
      days: "Thursday",
      // time: "1730 - 1930"
    },
    {
      days: "Friday",
      // time: "0900 - 1800"
    },
    {
      days: "Saturday",
      // time: "0900 - 1200"
    },
  ];

  return (
    <div>
      <PageTitle title={"Services"} />

      <div className="grid grid-cols-1 mt-8 px-8 lg:grid-cols-2">
        <div>
          <h1 className="text-2xl mb-2 underline font-bold text-en-blue">Services & Pricing</h1>
          <ul className="lg:w-1/2 text-lg mt-4">
            {services.map((service, i) => (
              <li key={i} className="mb-6">
                <div className="mb-1 flex justify-between">
                  <span className="font-bold text-en-blue">{service.name}</span>
                  <span className="font-bold">{service.price}</span>
                </div>
                <span className="">{service.description}</span>
              </li>
            ))}
          </ul>

          <NavLink
            className="hidden lg:block text-center w-1/2 mt-16 ml-12 text-white bg-pink-400 whitespace-nowrap px-8 py-4 text-xl font-medium rounded-md hover:bg-pink-500 hover:text-gray-900"
            key={"Book Now"}
            to={"/booking"}
            end={false}
          >
            <span>Book Now</span>
          </NavLink>
        </div>

        <div>
          <h1 className="text-2xl mb-2 underline font-bold text-en-blue">Areas Covered</h1>
          <ul className="lg:w-1/2 text-lg pl-4 mt-4 list-disc">
            {areas.map((area, i) => (
              <li key={i} className="mb-px">
                <span className="font-bold text-en-blue">{area}</span>
              </li>
            ))}
          </ul>

          <h1 className="text-2xl mb-2 mt-8 underline font-bold text-en-blue">Availability</h1>
          <ul className="lg:w-1/2 text-lg pl-4 mt-4 list-disc">
            {workingHours.map((wh, i) => (
              <li key={i} className="mb-2">
                <div className="flex justify-between">
                  <span className="font-bold text-en-blue">{wh.days}</span>
                  <span className="font-bold">{wh.time}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <NavLink
          className="lg:hidden text-center w-1/2 mx-auto mt-16 text-white bg-pink-400 whitespace-nowrap px-8 py-4 text-xl font-medium rounded-md hover:bg-pink-500 hover:text-gray-900"
          key={"Book Now"}
          to={"/booking"}
          end={false}
        >
          <span>Book Now</span>
        </NavLink>
      </div>
    </div>
  )
}