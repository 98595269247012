import { Outlet, useNavigate } from "react-router-dom";
import { ColourLogo } from "../Assets/Logos";
import Navigation from "../Components/Navigation";

export default function MainLayout() {

  return (
    <div className="h-full min-h-full flex flex-col bg-gray-100">
      {/* Top Bar */}
      <header className="px-5 flex justify-between bg-en-blue">

        {/* Banner */}
        <div className="m-3 flex items-center jusify-center">
          <div className="bg-white my-auto rounded-full">
            <a href="/">
              <ColourLogo className="p-1 w-auto h-[14vh]" />
            </a>
          </div>
          <div className="hidden text-white p-4 my-auto md:inline">
            <h1 className="text-2xl lg:text-4xl whitespace-nowrap font-bold font-roboto">The Ear Nurse</h1>
            <h1 className="text-lg lg:text-xl font-neucha">Professional Mobile Wax Removal Service</h1>
          </div>
        </div>

        {/* Quotes */}
        <div>

        </div>

        {/* Nav */}
        <Navigation />
      </header>

      {/* Main area */}
      <main className="p-4 grow overflow-auto">
        <Outlet />
      </main>

      <footer className="w-full p-2 bg-en-blue border-t">
        <div className="h-full my-auto pt-2 text-center text-white">
          Terms and conditions, privacy policy
        </div>
      </footer>

      {/* Not like this, stuck to bottom of outlet */}
      {/* Footer */}
      {/* <footer className="w-full h-[5vh] bg-en-blue fixed bottom-0 border-t">
        <div className="h-full my-auto text-center text-white">
          
        </div>
      </footer> */}
    </div>
  )
}